import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import BannerNovaHistoriaDesktop from '../../../assets/img/bannerHomeCaracol/nova-historia-desktop.png';
import BannerNovaHistoriaMobile from '../../../assets/img/bannerHomeCaracol/nova-historia-mobile.png';


function BannerNewHistory() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    

    const typeDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';

    return (
        <a target='_blank'
          href={`https://comunicados.parquecaracol.com.br/uma-nova-historia`}
        >
            <Row className="p-0 m-0">
                    {typeDevice === 'desktop' ? (
                      <img
                        className="p-0 m-0"
                        src={BannerNovaHistoriaDesktop}
                        alt="Banner Uma Nova História"
                      />
                    ) : (
                      <img
                        className="p-0 m-0"
                        src={BannerNovaHistoriaMobile}
                        alt="Banner Uma Nova História"
                      />
                    )}
                  </Row>
        </a>
    )
}

export default BannerNewHistory;